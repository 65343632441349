import { SampleService } from "@/services";

export default {
  name: "Sample",
  data () {
    return {
      sampleService: new SampleService(),
      headers: [{
        text: this.$t("SAMPLE.NAME"),
        value: "name",
        sortable: true,
        width: "180px",
        iconOrder: "$arrow_upward_rounded",
        iconOpen: "$arrow_down",
        iconClose:"$keyboard_arrow_up_rounded"
      }],
      items: []
    }
  },
  async created() {
    this.getData();
  },
  methods: {
    async getData() {
      const data = await this.sampleService.getAll();
      console.log("got data >> ", data);
      this.items = data;
    }
  }
}