var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "header-home" },
    [
      _c("z-card-template", {
        attrs: { title: _vm.$t(_vm.$route.meta.title) },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        primary: "",
                        color: "primary",
                        text: _vm.$t("SAMPLE.GET_DATA"),
                      },
                      on: { click: _vm.getData },
                    }),
                    _c("z-table", {
                      attrs: {
                        small: "",
                        headers: _vm.headers,
                        items: _vm.items,
                        autoPagination: true,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }