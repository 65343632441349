import { render, staticRenderFns } from "./index.vue?vue&type=template&id=909d9230&"
import script from "./Sample.js?vue&type=script&lang=js&"
export * from "./Sample.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kalil/Documents/projects/zoox/data-to-act-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('909d9230')) {
      api.createRecord('909d9230', component.options)
    } else {
      api.reload('909d9230', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=909d9230&", function () {
      api.rerender('909d9230', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Pages/Sample/index.vue"
export default component.exports